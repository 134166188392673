import React from 'react';

import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Rest, FormUtils, Functions} from '../../utils/utils';
import {FormInstance, Store} from 'rc-field-form/lib/interface';
import {notification} from 'antd';
import FormErrorField from '../../utils/form/formErrorField';
import EnumCodeIdentificationType from '../../enum/EnumCodeIdentificationType';
import ValidatePatientData from '../entities/validatePatientData';
import View from './newPatient';
import TutorData from './entities/tutorData';
import PatientData from './entities/patientData';

interface IProps {
    visible?: boolean;
    currentTutor?: TutorData;
    patient?: PatientData;
    editPatient?: boolean;
    onClose: () => void;
    addTutor: () => void;
    saveNewPatient: (values: Store) => void;
    editTutor: () => void;
    deleteTutor: () => void;
    fromExistingPerson?: boolean;
    tutorConsentimiento?: boolean;
    getTutorConsentimiento: (idPersona: number) => void;
}

export interface IState {
    mutualidadList?: { idMutualidad: number, nameMutualidad: string }[];
    aseguradoraList?: { idAseguradora: number, nameAseguradora: string }[];
    isMenor?: boolean;
    idMutualidad?: number;
    errorFields: FormErrorField[];
}

class NewPatientContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        errorFields: []
    };

    public componentDidMount() {
        if (this.props.visible) {
            this.loadData();
        }
    }

    public render() {
        return (
            <View
                {...this.state}
                visible={this.props.visible}
                onCancel={() => this.props.onClose()}
                onSubmit={this.validateAndSave}
                onChangeDocType={this.onChangeDocType}
                getMutualidades={this.getMutualidades}
                getAseguradoras={this.getAseguradoras}
                addTutor={this.props.addTutor}
                currentTutor={this.props.currentTutor}
                editTutor={this.props.editTutor}
                deleteTutor={this.props.deleteTutor}
                patient={this.props.patient}
                editPatient={this.props.editPatient}
                fromExistingPerson={this.props.fromExistingPerson}
                tutorConsentimiento={this.props.tutorConsentimiento}
                getTutorConsentimiento={this.props.getTutorConsentimiento}
            />
        );
    }

    private loadData = () => {
        if (this.props.patient && this.props.patient.typeIdentificacion === EnumCodeIdentificationType.MENOR) {
            this.setState({isMenor: true});
        }
        this.getMutualidades();
        this.getAseguradoras(this.props.patient?.idMutualidad);
    }

    private getMutualidades = (idAseguradora?: number) => {

        if (!idAseguradora) {
            Rest<{ type: string }, { idMutualidad: number, nameMutualidad: string }[]>().operation({type: 'SelectAllMutualidades'})
                .then(response => this.setState({mutualidadList: response}));
        } else {
            Rest<{ type: string, idAseguradora: number }, { idMutualidad: number, nameMutualidad: string }[]>()
                .operation({type: 'SelectMutualidadesByAseguradora', idAseguradora: idAseguradora})
                .then(response => this.setState({mutualidadList: response}));
        }
    }

    private getAseguradoras = (idMutualidad?: number) => {
        if (!idMutualidad) {
            Rest<{ type: string }, { idAseguradora: number, nameAseguradora: string }[]>().operation({type: 'SelectAllAseguradoras'})
                .then(response => this.setState({aseguradoraList: response, idMutualidad: undefined}));
        } else {
            Rest<{ type: string, idMutualidad: number }, { idAseguradora: number, nameAseguradora: string }[]>()
                .operation({type: 'SelectAseguradorasByMutualidad', idMutualidad: idMutualidad})
                .then(response => this.setState({aseguradoraList: response, idMutualidad: idMutualidad}));
        }
    }

    private onChangeDocType = (docType: number) => {
        if (docType === EnumCodeIdentificationType.MENOR) {
            this.setState({isMenor: true});
        } else {
            this.setState({isMenor: false});
        }
    }

    private validateAndSave = (form: FormInstance) => {
        this.setState({errorFields: []}, () => {
            form.validateFields().then((values) => {
                this.validate(values).then(errors => {

                    const age = Functions.calculateAge(values.dateBirt);
                    if (!errors || errors.length === 0) {
                        
                        if (((this.state.isMenor || age < 16) && (!this.props.currentTutor))) {
                            notification.info({
                                message: this.props.t('menorError'),
                                placement: 'topRight'
                            });
                        } else if (this.state.isMenor && age>=16) {
                            notification.info({
                                message: this.props.t('menorErrorNIF'),
                                placement: 'topRight'
                            });
                        } else {
                            this.props.saveNewPatient(values);
                        }
                    } else {
                        this.setState({errorFields: errors});
                    }
                });
            });
        });
    }

    private validate = (values: Store) => {

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            Rest<{ type: string; codeIdentification?: string, typeIdentificacion: number, email: string, cardNumber?: string, 
                namePatient: string, surname1Patient: String, surname2Patient?: String, dateBirtPatient: Date, idPersona?: number,
                fromExistingPerson: boolean,
                idTutor?: number }, ValidatePatientData>().operation({
                type: 'ValidatePatient',
                typeIdentificacion: values.typeDoc,
                codeIdentification: values.codeIdentification ? values.codeIdentification : undefined,
                email: values.email,
                cardNumber: values.cardNumber ? values.cardNumber : undefined,
                namePatient: values.name,
                surname1Patient: values.surname1,
                surname2Patient: values.surname2,
                dateBirtPatient: values.dateBirt,
                idPersona: this.props.patient?.idPaciente,
                fromExistingPerson: typeof this.props.fromExistingPerson === 'undefined'? false : this.props.fromExistingPerson,
                idTutor: this.props.currentTutor?.idPersona
            }, true).then(response => {
                if (response) {
                    let errors: FormErrorField[] = [];

                    if (response.docIdentidadError) {
                        const dniError = {
                            fieldName: 'codeIdentification',
                            errorMessage: this.props.t('formatoIncorrectoError')
                        };
                        errors = FormUtils.addError(this.state.errorFields, dniError);
                    }
                    if (response.isDniExistente) {
                        const dniError = {
                            fieldName: 'codeIdentification',
                            errorMessage: this.props.t('pacienteExistenteError')
                        };
                        errors = FormUtils.addError(this.state.errorFields, dniError);
                    }
                    if (response.isMailError) {
                        const emailError = {fieldName: 'email', errorMessage: this.props.t('formatoIncorrectoError')};
                        errors = FormUtils.addError(this.state.errorFields, emailError);
                    }
                    if (response.isCardError && false) {
                        const cardError = {
                            fieldName: 'cardNumber',
                            errorMessage: this.props.t('formatoIncorrectoError')
                        };
                        errors = FormUtils.addError(this.state.errorFields, cardError);
                    }
                    if (response.isCardNumberExistente) {
                        const cardError = {
                            fieldName: 'cardNumber',
                            errorMessage: this.props.t('pacienteCardExistenteError')
                        };
                        errors = FormUtils.addError(this.state.errorFields, cardError);
                    }
                    if (response.isTutorExistente) {
                        errors.push({fieldName: 'name'});
                        errors.push({fieldName: 'surname1'});
                        errors.push({fieldName: 'surname2'});
                        errors.push({fieldName: 'dateBirt'});

                        notification.info({
                            message: this.props.t('isTutorExistenteError'),
                            placement: 'topRight'
                        });
                    }

                    resolve(errors);

                } else {
                    resolve([]);
                }
            });
        });
    }
}

export default withTranslation('newPatientPrescription')(withRouter(NewPatientContainer));